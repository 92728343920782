import {computed, reactive, toRefs } from "vue";
import moment from "moment"

const filterState = reactive({
    startDate: "1900-01-01",
    endDate: "2999-01-01",
    selectedMembers: [],
    filteredMembers: [],
    filteredIssues: [],
    totalTimeSpend: 0,
    closedIssues: 0,
    openIssues:0,
    completed: 0,
    donutSpinner: [],
    donutTimeSpend: [],
    issuesStackBar: []
})

export function handleFilter() {
    const setStartDate = (startDate) => {
        if (startDate) {
            filterState.startDate = startDate
        }
    }

    const setEndDate = (endDate) => {
        if (endDate) {
            filterState.endDate = moment(endDate).add(1, 'd').format("YYYY-MM-DD")
        }
    }

    const setSelectedMembers = (selectedMembers) => {
        filterState.selectedMembers = selectedMembers
    }

    const setFilteredMembers = (selectedMembers) => {
        filterState.filteredMembers = selectedMembers
    }

    const setFilteredIssues = (issues, assignees) => {
        filterState.filteredIssues = []

        if (issues && assignees) {
            assignees.forEach(({name: assigneeName}) => {
                let _tmp = {
                    person: assigneeName,
                    issues: [],
                    spent: 0
                }

                issues.map(issue => {
                    let found = issue.assignees.nodes.find(item => item.name === assigneeName)
                    if (found !== undefined) {
                        _tmp.issues.push(issue)
                    }
                })

                _tmp.spent = _tmp.issues.reduce((total, issue) => {
                    return total + issue.totalTimeSpent
                }, 0);
                filterState.filteredIssues.push(_tmp)
            })
        }
    }

    const setDonutSpinner = (result) => {
        filterState.donutSpinner = {...result}
    }

    const setDonutTimeSpend = (result) => {
        filterState.donutTimeSpend = {...result}
    }

    const setTotalTimeSpend = (issues) => {
        filterState.totalTimeSpend = issues.reduce((total, issue) => {
            return total + issue.totalTimeSpent
        }, 0)
    }

    const setOpentIssues = (issues) => {
        filterState.openIssues = issues.filter(issue => issue.state === 'opened')
    }
    const setClosedIssues = (issues) => {
        filterState.closedIssues = issues.filter(issue => issue.state === 'closed')
    }

    const setComplete = (issues) => {
        let _closed = issues.filter(issue => issue.state === 'closed').length
        let _total = issues.length
        let _res = (parseFloat(_closed) / parseFloat(_total)).toFixed(2) * 100
        filterState.completed = isNaN(_res) ? 0 : _res.toFixed(0)
    }

    const setIssuesStats = (result) => {
        filterState.issuesStackBar = {...result}
    }

    // set data

    const getStartDate = computed(() => filterState.startDate)

    const getEndDate = computed(() => filterState.endDate)

    const getFilteredMembers = computed(() => filterState.filteredMembers)

    const getSelectedMembers = computed(() => filterState.selectedMembers)

    const getFilteredIssues = computed(() => filterState.filteredIssues)

    const getTotalTimeSpend = computed(() => filterState.totalTimeSpend)

    const getDonutSpinner = computed(() => filterState.donutSpinner)

    const getDonutTimeSpend = computed(() => filterState.donutTimeSpend)

    const getBarStackedIssues = computed(() => filterState.issuesStackBar)

    return {
        setStartDate,
        setEndDate,
        setFilteredMembers,
        setFilteredIssues,
        setTotalTimeSpend,
        setDonutSpinner,
        setDonutTimeSpend,
        setSelectedMembers,
        getStartDate,
        getEndDate,
        getFilteredIssues,
        getFilteredMembers,
        getTotalTimeSpend,
        getDonutSpinner,
        getDonutTimeSpend,
        getSelectedMembers,
        setOpentIssues,
        setClosedIssues,
        setComplete,
        setIssuesStats,
        getBarStackedIssues,
        ...toRefs(filterState)
    }
}


export function formatDateFilter(val, format = 'MM/DD/YYYY hh:mm') {
    return moment(String(val)).format(format)
}


